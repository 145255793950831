/**
 * @module
 */
import domutil from '../../../lib/domutil.js'
import autocomplete from '../../../lib/autocomplete.js'
import {setLocaleByIdentifier as setSSLocaleByIdentifier, setLocale as setSSLocale, getString} from '../resources/strings.js'
import icons from '../resources/icons.js'
import ResultRenderer from './ResultRenderer.js'
import Filter from "../filter/Filter.js"

/**
 *
 * Built-in UI for Septima Search
 *
 * @example
 * var view = new View(
 *   {
 *     input : 'septimasearch',
 *     placeholder : 'Search'
 *   });
 * @sspath Septima.Search
 * */
export default class {
  /**
   *
   * @param {Object} options
   * @param options.input {string|Object} Name of DOM element or the element itself in which to draw the input field
   * @param [options.limit=15] {number} Approximate number of results to show when searching multiple targets
   * @param options.controller {module:js/Controller} The controller from which the view shows results
   * @param [options.detailsLayout] {Object} If set, this will be called upon to rener details when a result is selected
   * @param [options.filter] {Object} Filter for targets and details
   */

  constructor(options) {
    if (options === undefined || options.input === undefined)
      throw "View(options): Options.input is missing."

    this.options = options
    if(options.input instanceof Element || options.input instanceof HTMLDocument)
      this.container = options.input
    else
      this.container = document.getElementById(options.input)

    this.limit = 15
    if (options.limit !== undefined)
      this.limit = options.limit

    if (options.controller === undefined)
      throw "View(options): controller is missing."
    else
      this.myController = options.controller

    this.myController.addOnSelectHandler(this.onControllerSelect.bind(this))

    if (options.filter === undefined)
      this.defaultFilter = new Filter()
    else
      this.defaultFilter = new Filter(options.filter)
      
    this.detailsLayout = null
    if (options.detailsLayout) {
      this.detailsLayout = options.detailsLayout
      if (this.detailsLayout.setResultActionListeners) {
        let resultActionListeners = {}

        // Register self as select listener
        resultActionListeners['select'] = (result)=> {
          this.resultSelect(result)
        }
        
        // If listeners in options -> register those
        if (typeof options.onHover !== 'undefined')
          resultActionListeners['hover'] = (result, infoItems) => {
            options.onHover(result, infoItems)
          }

        if (typeof options.onFocus !== 'undefined') {
          resultActionListeners['focus'] = (result) => {
            options.onFocus(result)
          }
          if (options.focusIcon)
            resultActionListeners['focusIcon'] = options.focusIcon
          else
            resultActionListeners['focusIcon'] = icons.mapPointGrey
        }

        this.detailsLayout.setResultActionListeners(resultActionListeners)
      }
      
      if (this.detailsLayout.setDetailActionListeners) {
        let detailActionListeners = {}
        
        if (typeof options.onDetailHeader !== 'undefined')
          detailActionListeners['onDetailHeader'] = (result, flattenedItems, items) => {
            return options.onDetailHeader(result, flattenedItems, items)
          }
        
        if (typeof options.onDetailItem !== 'undefined')
          detailActionListeners['onDetailItem'] = options.onDetailItem
        
        this.detailsLayout.setDetailActionListeners(detailActionListeners)
      }
    }

    this.doMouseOver = true
    if (options.mouseover === false)
      this.doMouseOver = options.mouseover

    this.charLimit = 0
    if (options.charLimit)
      this.charLimit = options.charLimit

    this.placeHolderText = getString("search")
    if (options.placeHolderText)
      this.placeHolderText = options.placeHolderText
    if (options.placeholder)
      this.placeHolderText = options.placeholder

    this.showDescriptionInMore = true

    this.showNewQueryIcon = true

    //Vars
    this.visibleInterests = []
    this.isVisible = false
    this.advancedIsVisible = false
    this.$gui = null
    
    this.promotedTypes = []
    this.promotedTargets = []
    this.buttonCount = 0

    /*
    this.$suggestionContainer = null
    this.$suggestionList = null
     */
    this.header = null
    this.fixedDisplay = null
    this.fixedDisplayHideFunction = null
    this.fixedDisplayText = null
    this.hasFixedDisplay = false
    this.myInputField = null
    //Target related stuff
    this.target = null
    this.targetBit = null
    this.targetLabel = null
    this._sources = {}

    this.resultList = null
    this.searchBackGround = null
    this.details = null

    this.hasStarted = false
    this.draw()
    this.hasStarted = true
    this.onChangeHandlers = []
    if (options.onChangeHandler)
      this.onChangeHandlers.push(options.onChangeHandler)
  }

  /**
  * Gets fired when the user selects a result by clicking or using the
  * keyboard to select an element.
  *
  * @param {Object} result
  *   The result object that was selected.
  */

  addOnChangeHandler(onChangeHandler) {
    this.onChangeHandlers.push(onChangeHandler)
  }

  callOnChangeHandlers(newValue) {
    for (let onChangeHandler of this.onChangeHandlers)
      onChangeHandler(newValue)
  }

  async bacResultSelect(result) {
    if (this.details!==null)
      this.details.close()
    this.myController.onSelect(result)
  }

  async resultSelect(result) {
    this.myController.onSelect(result)
  }

  async onControllerSelect(result) {
    if (result.isNewQuery()) {
      setTimeout(() => this.doTextSearch(result.newquery, result.source, result.typeId), 50)
    } else {
      if (this.options.showFixedTextOnSelect) {
        this.blur(true)
        this.showFixed(result.title)
      }
      if ( this.shouldDetailsBeShown(result)) {
        this.doDetail(result)
      }
    }
  }

  //Public Functions
  setMaxHeight(intHeight) {
    if (intHeight !== null && intHeight > 100 && this.resultList !== null) {
      //this.resultList.style["max-height"] = intHeight + 'px'
      domutil.css(this.resultList, "max-height", intHeight + 'px')
      if (this.details!==null) {
        this.details.setMaxHeight(intHeight)
      }
    }
  }

  top() {
    if (this.myInputField !== null)
      return domutil.offset(this.myInputField).top + 60
    else
      return null
  }

  closeFixedText() {
    this.fixedDisplay.hide()
    this.fixedDisplayHideFunction()
    this.header.show()
    this.hasFixedDisplay = false
    this.evaluateResultsVisibilityInterest()
  }

  /**
	 * Display a fixed text which can be closed by the user. When the text is closed the hidFunction will be called
	 * @param {string} text. Text to be displayed in the search box
	 * @param {function} hideFunction. Function to call when user closes the text.
	 */
  showFixedText(text, hideFunction) {
    this.header.hide()
    this.fixedDisplayText.html(text)
    this.fixedDisplay.show()
    this.hasFixedDisplay = true
    this.evaluateResultsVisibilityInterest()
    this.fixedDisplayHideFunction = hideFunction
  }

  /**
	 * Hide the fixed text and show the input field
	 */
  hideFixed() {
    domutil.removeClass(this.container, "ssFixed")
    if (this.fixedDisplayHideFunction) {
      this.fixedDisplayHideFunction()
    }
  }

  /**
	 * Display a fixed text instead of the input field
	 * @param {string} text. Text to be displayed in the search box
	 * @param {function} hideFunction. Function to call when user closes the text.
	 */
  showFixed(text, hideFunction) {
    domutil.empty(this.myFixedText)
    this.myFixedText.value = text
    domutil.addClass(this.container, "ssFixed")
    this.fixedDisplayHideFunction = hideFunction
  }

  /**
	 * @private
	 */
  async draw() {
    this.$gui = this.createGui(this.container)
    this.bac = autocomplete(
      this.myInputField,
      {
        cacheLimit: 0,
        selectKeys: [13],
        crossOrigin: true,
        charLimit: this.charLimit,
        mouseOver: this.doMouseOver,
        noResultsText: getString("noResults")
      }, {
        fetchRemoteData:      (query, completeCallback, timeout, crossOrigin)=>this.fetchRemoteData(query, completeCallback, timeout, crossOrigin),
        themeResult:          (result)=>this.themeResult(result),
        insertSuggestionList: (results, input)=>this.insertSuggestionList(results, input),
        select:               (result)=>this.bacResultSelect(result),
        show:                 ()=>this.bacEventShow(),
        hide:                 ()=>this.bacEventHide(),
        getGroup:             (result)=>this.getGroup(result),
        beginFetching:        ()=>this.beginFetching(),
        finishFetching:       ()=>this.finishFetching(),
        afterClear:           ()=>this.afterClear(),
        backSpace:            ()=>this.backSpace(),
        onChange:             (query)=>this.onViewChange(query)
      })

    this.sourcesLoaded = this.loadSources()
  }

  async loadSources() {
    let s = await this.myController.getSources()
    //returns array [{source: "sss", types: [resultType..]}, ]
    //Convert to object {sss: {typeId: type, }, }
    const groups = []
    for (let sourceEntry of s) {
      let source = sourceEntry.source
      groups.push(source.toLowerCase())
      this._sources[source] = {}
      for (let type of sourceEntry.types) {
        this._sources[sourceEntry.source][type.id] = type
        //let targetId = source + "." + type.id 
        //groups.push(targetId)
      }
    }
    this.bac.createGroups(groups)
  }

  /**
   * From a given result object, return it's group name (if any). Used for
   * grouping results together.
   *
   * @param {Object} result
   *   The result object.
   *
   * @returns {String}
   *   The group name, may contain HTML. If no group, don't return anything.
   */
  getGroup(result) {
    //let group = result.getTargetId()
    let group = result.source.toLowerCase()
    return group
  }


  /**
  * Called when remote fetching begins.
  *
  * <br /><br /><em>Default behavior: Adds the CSS class "fetching" to the
  * input field, for styling purposes.</em>
  *
  * @param {Object} $input
  *   The input DOM element, wrapped in jQuery.
  */
  beginFetching() {
    domutil.addClass(this.searchBackGround, "ssSearchBackgroundLoading")
  }

  /**
  * Called when fetching is finished. All active requests must finish before
  * this function is called.
  *
  * <br /><br /><em>Default behavior: Removes the "fetching" class.</em>
  *
  * @param {Object} $input
  *   The input DOM element, wrapped in jQuery.
  */
  finishFetching() {
    domutil.removeClass(this.searchBackGround, "ssSearchBackgroundLoading")
  }

  /**
   * @param {string} locale. 
   */
  setLocale(locale) {
    setSSLocale(locale)
    this.setPlaceholderText(getString("search"))
    this.bac.setNoResultsText(getString("noResults"))
  }

  /**
   * @param {string} setLocaleByIdentifier.
   */
  setLocaleByIdentifier(localeIdentifier) {
    setSSLocaleByIdentifier(localeIdentifier)
    this.setPlaceholderText(getString("search"))
    this.bac.setNoResultsText(getString("noResults"))
  }
  
  afterClear() {
    this.clearSuggestions()
  }

  backSpace() {
    if (this.targetLabel.innerHTML.length > 0) {
      this.removeTarget()
      this.bac.repeatSearch()
    }
  }

  clearAll() {
    this.hideFixed()
    this.doTextSearch("")
  }

  focus() {
    this.hideFixed()
    this.myInputField.focus()
  }

  blur(force) {
    this.myInputField.blur()
    if (force)
      this.hideResults()
    document.activeElement.blur()
  }

  refresh() {
    if (this.hasStarted)
      this.bac.repeatSearch()
  }

  setPlaceholderText(text) {
    this.placeHolderText = text
    domutil.setAttributes(this.myInputField, {
      placeholder: this.placeHolderText,
    })
  }

  clearSuggestions() {
    /*
    domutil.removeClass(this.$suggestionList, "ssSuggestionsContent")
    while(this.$suggestionList.firstChild) {
      this.$suggestionList.removeChild(this.$suggestionList.firstChild)
    }
     */
  }

  setResults(results) {
    this.bac.renderResults(results)
  }

  selectHighLighted() {
    this.bac.select()
  }
  //Private functions

  /**
   * Insert the results list into the DOM and position it properly.
   * @param {Object} $results
   *   The UL list element to insert, wrapped in jQuery.
   * @param {Object} $input
   *   The text input element, wrapped in jQuery.
  */
  insertSuggestionList(results) {
    this.resultList = results
    this.resultscontainer.append(results)
  }

  /**
   *
   * Fetch remote result data and return it using completeCallback when
   * fetching is finished. Must be asynchronous in order to not freeze the
   * Better Autocomplete instance.
   *
   * @param {String} query
   *   
   *
   * @param {Function} completeCallback
   *   This function must be called, even if an error occurs. It takes zero
   *   or one parameter: the data that was fetched.
   *
   */
  fetchRemoteData(query, completeCallback) {
    this.clearSuggestions()
    if (this.details !== null)
      this.details.clear()

    if (query.length === 0)
      domutil.removeClass(this.searchBackGround, "ssSearchBackgroundHasContent")
    else
      domutil.addClass(this.searchBackGround, "ssSearchBackgroundHasContent")

    let fetchFilter
    if (this.target !== null) {
      fetchFilter = new Filter()
      fetchFilter.targets.addTarget({
        source: this.target.source,
        types : [this.target.typeId]
      })
      fetchFilter.details = this.defaultFilter.details
    } else {
      fetchFilter = this.defaultFilter
    }

    this.myController.fetch(query, (result, isLast)=>{
      this.routeRemoteData(result, isLast, completeCallback)
    }, this.limit, fetchFilter)
    
  }

  onViewChange(query) {
    if (this.target !== null) {
      this.callOnChangeHandlers({source: this.target.source, typeId: this.target.typeId, query: query})
    } else {
      this.callOnChangeHandlers({source: null, typeId: null, query: query})
    }
  }
  

  /**
  * Given a result object, theme it to HTML.
  *
  * @param {Object} result
  *   The result object that should be rendered.
  *
  * @returns {String}
  *   HTML output, will be wrapped in a list element.
  */
  themeResult(result) {
    let extraButtonDefs
    if (result.isNewQuery()) {
      extraButtonDefs = [{"buttonText": "", "buttonImage": icons.list.newQueryIcon, fixed: true}]
      return ResultRenderer.themeResult(result, [], extraButtonDefs)
    }else {
      if (this.shouldDetailsBeShown(result)) {
        extraButtonDefs = [
          {
            "buttonText":  getString("doDetails"),
            "buttonImage": icons.list.detailsIcon,
            "callBack": (result) => {
              this.doDetail(result)
            },
            fixed: true
          }]
        return ResultRenderer.themeResult(result, [], extraButtonDefs)
      } else {
        if (result.searcher) {
          let customButtonDefs = result.searcher.getCustomButtonDefs(result)
          return ResultRenderer.themeResult(result, customButtonDefs)
        }
      }
    }
  }

  shouldDetailsBeShown(result) {
    let hasdetailHandlerDefs = result.searcher.hasdetailHandlerDefs(result, this.defaultFilter)
    let customButtonDefs = result.searcher && result.searcher.getCustomButtonDefs(result)
    let moreThanFourCustomButtonDefs = customButtonDefs ? customButtonDefs.length > 4 : false
    return this.details!==null && (hasdetailHandlerDefs || moreThanFourCustomButtonDefs )
  }
  
  async promote(source, typeId) {
    if (typeof this._sources[source] === 'undefined' || typeof this._sources[source][typeId] === 'undefined')
      await this.loadSources()
    if (typeof this._sources[source] !== 'undefined' && typeof this._sources[source][typeId] !== 'undefined') {
      let type = this._sources[source][typeId]
      this.promotedTypes.push(type)
      this.promotedTargets.push({source, typeId})
      this.renderPromote(source, type)
    }
  }
  
  routeRemoteData(results, isLast, completeCallback) {
    //let resultTarget = {source: result.source, typeId: result.typeId}
    if (isLast)
      completeCallback ([], true)
    else {
      if (this.promotedTargets.length > 0) {
        if (this.target) {
          let targetResults = results.filter(result => result.source == this.target.source && result.source == this.target.source)
          completeCallback (targetResults, isLast)
        } else {
          let nonPromotedResults = results.filter(result => this.promotedTargets.findIndex(target => target.source == result.source && target.typeId == result.typeId) == -1)
          completeCallback (nonPromotedResults, isLast)
        }
      } else {
        completeCallback (results, isLast)
      }
    }

  }

  renderPromote(newSource, newType) {
    this.createButton(newType.iconURI,  newType.plural, ()=>{
      this.showTarget(newSource, newType.id)
      this.bac.doSearch("")
    })
  }
  
  createButton(iconURI, title, callback) {
    this.buttonCount++
    if (this.buttonCount == 1) {
      domutil.css(this.buttons, "float", "right")
      domutil.removeClass(this.buttons, "ssEmpty")
    }
    let buttonElement = domutil.createElement("div", "ssPromotedIcon")
    domutil.css(buttonElement, "background-image", "url(" + iconURI + ")")
    domutil.setAttribute(buttonElement, "title", title)
    buttonElement.addEventListener("click", ()=>{
      callback()
    })

    this.buttons.append(buttonElement)
    let width = this.buttonCount * 42
    domutil.css(this.buttons, "width", width + "px")
    this.setWidths()
  }

  setWidths() {
    let promotesWidth = this.buttons.getBoundingClientRect().width
    let headerWidth = this.header.getBoundingClientRect().width
    this.searchBackGround.style.width = (headerWidth-promotesWidth-5) + "px"

    let width
    if (this.target) {
      width = this.searchBackGround.getBoundingClientRect().width - this.targetBit.getBoundingClientRect().width - 45
    } else {
      width = this.searchBackGround.getBoundingClientRect().width - 45
    }
    this.myInputField.style.width = width + "px"
    this.myFixedText.style.width = (width-2) + "px"
  }
  
  //Everything related to target
  removeTarget() {
    this.hideFixed()
    this.targetLabel.innerHTML =""
    domutil.hide(this.targetBit)
    domutil.setAttribute(this.targetBit, "aria-hidden", true)
    this.targetBit.className = "ssTargetbit"
    this.target = null
    // NOTE: commented out because it causes a regression in OneDoor due to faulty implementation there that should be fixed eventually
    //this.refresh()
    this.setWidths()
  }

  doTextSearch(searchText, source, typeId) {
    this.clearSuggestions()
    this.myController.cancelFetches()
    if (source)
      this.showTarget(source, typeId)
    else
      this.removeTarget()

    this.bac.doSearch(searchText)
  }


  async showTarget(source, typeId) {
    if (typeof this._sources[source] === 'undefined' || typeof this._sources[source][typeId] === 'undefined')
      await this.loadSources()
    if (typeof this._sources[source] !== 'undefined' && typeof this._sources[source][typeId] !== 'undefined') {
      let resultType = this._sources[source][typeId]
      this.targetLabel.innerHTML = resultType.plural
      this.targetBit.className = "ssTargetbit"
      domutil.addClass(this.targetBit, "ssTargetbit_" + typeId.toLowerCase().replace(/ /g, ""))
      domutil.show(this.targetBit)
      domutil.setAttribute(this.targetBit, "aria-hidden", false)
      this.target = {source, typeId}
      // NOTE: commented out because it causes a regression in OneDoor due to faulty implementation there that should be fixed eventually
      //this.refresh()
      this.setWidths()
    }
  }

  /**
	 * private internal method of DefaultView
	 * @private
	 */
  createGui($container) {

    domutil.addClass($container, "septimasearch")
    this.header = domutil.createElement("div", "ssSearchHeader")
    //header er opdelt i searchBackGround og promotes
    this.searchBackGround = domutil.createElement("div", "ssSearchBackground")
    //domutil.css(this.searchBackGround, "display", "inline-block")

    this.header.append(this.searchBackGround)

    this.targetBit = domutil.createElement("span", "ssTargetbit")
    this.targetLabel = domutil.createElement("span", "ssTargetlabel")
    const targetCloseButton = domutil.createElement("span", "ssTargetclosebutton")
    domutil.setAttribute(targetCloseButton, "aria-label", "Ryd emne")
    domutil.setAttribute(targetCloseButton, "role", "button")
    targetCloseButton.addEventListener("click", ()=>{
      this.removeTarget()
      this.bac.repeatSearch()
    })
    this.targetBit.append(this.targetLabel)
    this.targetBit.append(targetCloseButton)
    this.searchBackGround.append(this.targetBit)

    this.myInputField = domutil.createElement("input", "ssInput")
    domutil.setAttributes(this.myInputField, 
      {
        placeholder: this.placeHolderText,
        autocomplete: "off",
        role: "textbox",
        "aria-autocomplete": "list",
        "aria-haspopup": "true"
      }
    )
    this.searchBackGround.append(this.myInputField)
    this.myFixedText = domutil.createElement("input", "ssFixedText")
    domutil.setAttributes(this.myFixedText, 
      {
        "tabindex": "-1",
        "aria-hidden": "true"
      }
    )
    this.searchBackGround.append(this.myFixedText)
    this.myFixedText.addEventListener("mousedown", () => {
      this.focus()
      this.hideFixed()
    })
    this.myFixedText.addEventListener("mouseenter", () => {
      this.hideFixed()
    })
    this.myFixedText.addEventListener("focus", () => {
      this.focus()
      this.hideFixed()
    })
    this.myInputField.addEventListener("focus", () => {
      this.hideFixed()
    })
    const resizeObserver = new ResizeObserver(() => this.setWidths())
    resizeObserver.observe($container)
    
    const searchIcon = domutil.createElement("div", "ssSearchIcon")
    domutil.setAttribute(searchIcon, "aria-hidden", true)
    this.searchBackGround.append(searchIcon)

    const spinner = domutil.createElement("div", "ssSpinner")
    domutil.setAttribute(spinner, "aria-hidden", true)
    spinner.append(domutil.createElement("div", "bounce1"))
    spinner.append(domutil.createElement("div", "bounce2"))
    spinner.append(domutil.createElement("div", "bounce3"))

    this.searchBackGround.append(spinner)

    const closeButton = domutil.createElement("div", "ssCloseButton")
    this.searchBackGround.append(closeButton)

    this.buttons = domutil.createElement("div", "ssPromotes")
    domutil.addClass(this.buttons, "ssEmpty")
    //domutil.css(this.buttons, "display", "none")
    domutil.css(this.buttons, "width", "0px")
    this.header.append(this.buttons)
    
    this.resultscontainer = domutil.createElement("div", "ssSearchResult")

    $container.append(this.header)
    $container.append(this.resultscontainer)

    if (this.detailsLayout !== null)
      this.details = this.createDetailsView($container, this.detailsLayout, this.defaultFilter)

    if (this.doMouseOver) {
      $container.addEventListener("mouseleave", ()=>{
        this.guiMouseLeave()
      })
      this.myInputField.addEventListener("mouseenter", ()=>{
        this.guiMouseEnter()
      })
    }

    this.myInputField.addEventListener("focus", ()=>{
      this.inputFocus()
    })
    this.myInputField.addEventListener("blur", ()=>{
      this.inputBlur()
    })

    closeButton.addEventListener("click", ()=>{
      this.clearAll()
    })
    domutil.setAttribute(closeButton, "aria-label", "Ryd søgefeltet")
    domutil.setAttribute(closeButton, "role", "button")

    this.removeTarget()
  }

  //Control showing and hiding of GUI
  setResultsVisibilityInterest(party, interested) {
    let partyFound = false
    for (let visibleInterest of this.visibleInterests)
      if (visibleInterest.party == party) {
        partyFound = true
        visibleInterest.interested = interested
      }

    if (!partyFound)
      this.visibleInterests.push({party: party, interested: interested})

    if (interested && !this.hasFixedDisplay) {
      this.showResults()
    }else{
      let anyInterested = false
      for (let visibleInterest of this.visibleInterests)
        if (visibleInterest.interested)
          anyInterested = true


      if (anyInterested && !this.hasFixedDisplay)
        this.showResults()
      else
        this.hideResults()

    }
  }

  createDetailsView(container, layout, filter) {
    const details = {parent: this}
    details.filter = filter
    details.layout = layout
    details.results = []

    details.container = domutil.createElement("div", "ssDetails")
    const detailul = domutil.createElement("ul", "better-autocomplete")
    details.container.append(detailul)

    details.header = domutil.createElement("li", "ssDetailHeader")
    detailul.append(details.header)

    details.content = domutil.createElement("li", "ssDetailBody")
    domutil.addClass(details.content, "ssActive")

    detailul.append(details.content)

    container.append(details.container)
    domutil.removeClass(details.container, "ssActive")

    details.show = function() {
      if (typeof details.result !== 'undefined' && details.result !== null)
        domutil.addClass(details.container, "ssActive")
    }

    details.hide = function() {
      domutil.removeClass(details.container, "ssActive")
    }

    details.close = function() {
      if(details.result !== undefined && details.result !== null) {
        details.results = []
        details.hide()
        details.clear()
      }
    }

    details.setMaxHeight = function(intHeight) {
      //details.content.style["max-height"] = intHeight + 'px'
      let myIntHeight = intHeight - details.header.offsetHeight
      domutil.css(details.content, "max-height", myIntHeight + 'px')
      domutil.css(details.content, "overflow", 'auto')
    }
    
    details.clear = function() {
      if(details.result !== undefined && details.result !== null) {
        details.result = null
        details.layout.destroyUI()

        while(details.header.firstChild) {
          details.header.removeChild(details.header.firstChild)
        }
        while(details.content.firstChild) {
          details.content.removeChild(details.content.firstChild)
        }
      }
    }

    details.back = function() {
      if (details.results.length > 0) {
        const result = details.results.pop()
        details.parent.resultSelect(result)
        details.render(result)
      }
    }

    details.setResult = function(result) {
      if (details.result !== result) {
        if(details.result !== undefined && details.result !== null)
          details.results.push(details.result)

        details.render(result)
      }
    }

    details.render = async function(result) {
      details.clear()
      details.result = result
      let backButton
      let title
      if (details.results.length > 0) {
        title = details.results[details.results.length - 1].title
        backButton = domutil.createElement("div", "ssBackButton")
        let icon = domutil.createImageElement(icons.details.back, title)
        backButton.append(icon)
        let titleEl = domutil.createElement("div", "ssTxt")
        titleEl.innerText = title
        backButton.append(titleEl)
        backButton.addEventListener("click", ()=>{
          details.back()
        })
      }else{
        backButton = domutil.createElement("div", "ssBackButton")
        let icon = domutil.createImageElement(icons.details.back, getString("close"))
        backButton.append(icon)
        let titleEl = domutil.createElement("div", "ssTxt")
        titleEl.innerText = getString("close")
        backButton.append(titleEl)
        backButton.addEventListener("click", ()=>{
          details.close()
        })
      }
      details.header.append(backButton)

      let output = domutil.createElement("div", "ssResult")
      domutil.setAttribute(output, "aria-hidden", true)
      let imageContainer = domutil.createElement("div", "ssResultImage")
      if (result.hasOwnProperty("image") && result.image !== null && result.image !== "") {
        imageContainer.append(domutil.createImageElement(result.image))
        output.append(imageContainer)
      }else{
        let searcher = result.searcher
        if (searcher.hasOwnProperty("iconURI") && searcher.iconURI !== null && searcher.iconURI !== "")
          result.image = searcher.iconURI
        else
          result.image = icons.result.defaultIcon

        imageContainer.append(domutil.createImageElement(result.image))
        output.append(imageContainer)
      }
      output.append(imageContainer)

      let titleEl = domutil.createElement("div", "ssResultTitle")
      titleEl.innerText = result.title
      output.append(titleEl)

      if (!this.parent.showDescriptionInMore && result.hasOwnProperty("description") && result.description !== null) {
        let description = domutil.createElement("div", "ssResultDescription")
        description.innerText = result.description
        domutil.setAttribute(description, "title", result.description + "' \"" )
        output.append(description)
        domutil.setAttribute(details.header, "aria-label", result.description)
      } else {
        let resultDescription = domutil.createElement("div", "ssResultDescription")
        domutil.addClass(resultDescription, 'hide')
        output.append(resultDescription)
      }

      details.header.append(output)

      let ui = await details.layout.getUI(result, details.filter)
      details.content.append(ui)
      details.show()
    }

    details.container.addEventListener("mouseenter", ()=>{
      // details.mouseEnter()
    })
    details.container.addEventListener("mouseleave", ()=>{
      // details.mouseLeave()
    })

    return details

  }

  doDetail(result) {
    if (this.details!==null)
      if (result !== undefined) {
        this.details.setResult(result)
        if (this.isVisible)
          this.blur(true)
        else
          this.details.show()
      }
  }

  showResults() {
    if (!this.isVisible) {
      if (this.details !== null)
        this.details.hide()
      domutil.addClass(this.container, "ssActive")
      this.isVisible = true
    }
  }

  hideResults() {
    for (let visibleInterest of this.visibleInterests)
      visibleInterest.interested = false

    if (this.isVisible) {
      domutil.removeClass(this.container, "ssActive")
      this.isVisible = false
      if (this.details!==null)
        this.details.show()
    }
  }

  //Callbacks
  guiMouseEnter() {
    this.setResultsVisibilityInterest('gui', true)
    return true
  }

  guiMouseLeave() {
    this.setResultsVisibilityInterest('gui', false)
    return true
  }

  inputFocus() {
    this.setResultsVisibilityInterest('input', true)
  }

  inputBlur() {
    this.setResultsVisibilityInterest('input', false)
  }

  bacEventShow() {
    this.setResultsVisibilityInterest('bac', true)
    this.bac.show()
  }

  bacEventHide() {
    this.setResultsVisibilityInterest('bac', false)
  }

}
