/**
 * @module
 */
import Searcher from './Searcher.js'

export default class CVR_enhedSearcher extends Searcher {
  constructor(options= {}) {
    if (options.logger)
      options.logger.warn("CVR_enhedSearcher is deprecated - Use Septima.Search.DataApi.CvrSearcher")
    else
      
      if (console && console.warn) {
        try{
          
          console.warn("CVR_enhedSearcher is deprecated - Use Septima.Search.DataApi.CvrSearcher")
          // eslint-disable-next-line no-empty
        } catch(e) {}
      }
    
    super(options)
  }
  
}
