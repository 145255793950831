/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
export default class CvrVirksomhederForAdresseProvider extends DetailsHandlerDef {
  constructor(options= {}) {
    if (options.logger)
      options.logger.warn("CvrVirksomhederForAdresseProvider is deprecated - Use Septima.Search.DataApi")
    else
      
      if (console && console.warn) {
        try{
          
          console.warn("CvrVirksomhederForAdresseProvider is deprecated - Use Septima.Search.DataApi")
          // eslint-disable-next-line no-empty
        } catch(e) {}
      }

    if (!options.cvr_enhedSearcher)
      throw "CvrVirksomhederForAdresseProvider needs a CVR_enhedSearcher"

    let defaultOptions = {
      buttonText: "Virksomheder på husnummeret",
      buttonImage: options.cvr_enhedSearcher.iconURI
    }
    super(Object.assign(defaultOptions, options))

    this.mode = "list"
    if (options.mode)
      this.mode = options.mode
    this.iconURI = options.cvr_enhedSearcher.iconURI
    this.isApplicableFunction = this.isApplicable
    this.handlerFunction = this.myHandler
    this.cvr_enhedSearcher = options.cvr_enhedSearcher
  }

  isApplicable(result) {
    return (result.source === "Dawa" && result.typeId === "adresse")
  }

  async myHandler() {
    var items = []
    return items
  }
  
}