/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
/**
 * Decorates any result with simple text
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 * _type: Septima.Search.DummyMoreHandler
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * cvr_enhedSearcher.addDetailHandlerDef(new Septima.Search.DummyMoreHandler())
 *
 * @example <caption>ES6:</caption>
 * import DemoDetailsHandler from './searchers/detailhandlers/DummyMoreHandler.js'
 * dawaSearcher.addDetailHandlerDef(new DummyMoreHandler())
 * @api
 **/
export default class DummyMoreHandler extends DetailsHandlerDef {
  constructor(options = {}) {
    let defaultOptions = {
      handler: async(result) => {
        return [{type: 'textarea', text: "Simple more handler for " + result.title}]
      },
      more: true
    }
    super(Object.assign(defaultOptions, options))
  }
}
