/**
 * @module
 */
import LifaLinks from "../LifaLinks.js"
export default class LifaLinkForAdresseMatrikelHandler extends LifaLinks {
  constructor(options) {
    if (options.logger)
      options.logger.warn("LifaLinkForAdresseMatrikelHandler er deprecated. brug LifaLinks i stedet")
    else
      
      if (console && console.warn) {
        try{
          
          console.warn("LifaLinkForAdresseMatrikelHandler er deprecated. brug LifaLinks i stedet")
          // eslint-disable-next-line no-empty
        } catch(e) {}
      }
    super(options)
  }


}
