/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef.js"
import icons from "../../resources/icons.js"
/**
 * Viser attributer for et arcGis Server resultat
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 _type: Septima.Search.ArcGisFeatureInfoProvider
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * new Septima.Search.AdresserForHusnummerProvider()
 *
 * @example <caption>ES6:</caption>
 * import ArcGisFeatureInfoProvider from './searchers/detailhandlers/ArcGisFeatureInfoProvider.js'
 * new AdresserForHusnummerProvider()
 * @sspath Septima.Search
 **/
export default class ArcGisFeatureInfoProvider extends DetailsHandlerDef {
  constructor(options = {}) {
    let defaultOptions = {
      buttonText: "Detaljer",
      buttonImage: icons.details.moreHeader,
      more: true,
      autoDetectLinks: false
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler
    this.isApplicableFunction = this.isApplicable
    this.autoDetectLinks = options.autoDetectLinks
  }

  isApplicable(result) {
    const featureData = result.data
    if (result.searcher && featureData) {
      if (typeof featureData.hasDetails === "undefined") {
        featureData.hasDetails = false
        if (featureData.attributes) {
          for (let attribute in featureData.attributes)
            if (attribute != "OBJECTID" && attribute != "SHAPE.AREA" && attribute != "SHAPE.LEN") {
              featureData.hasDetails = true
              return true
            }
        }
      } else {
        return featureData.hasDetails
      }
    }
    return false
  }

  async myHandler(result, logger, contextResult) {
    // 
    let layer = result.searcher.getLayerByName(result.typeId)
    let items = []
    if (!contextResult) {
      if (layer.description && layer.description.length > 3)
        items.push({ type: "textarea", text: layer.description })
    }
    const featureData = result.data
    if (featureData.attributes) {
      for (let attribute in featureData.attributes)
        if (attribute != "OBJECTID" && attribute != "SHAPE.AREA" && attribute != "SHAPE.LEN") {
          let label = this.getAliasForAttribute(attribute, layer.fields) || attribute
          let value = featureData.attributes[attribute] ? featureData.attributes[attribute] : "-"
          let link = this.isLinkAttribute(label, value)
          if (link && this.autoDetectLinks) {
            items.push({ type: "link", linkTitle: value, link: value, label: label })
          } else
            items.push({ type: "labelvalue", label: label, value: value })
        }
    }
    return items
  }

  getAliasForAttribute(attribute, fields) {
    for (let field of fields) {
      if (field.name == attribute && field.alias)
        return field.alias
    }
  }
  isLinkAttribute(label, value) {
    if (label.toLowerCase() === 'url')
      return true

    if (value.toString().startsWith('http'))
      return true

    return false
  }

  getTypeForAttribute(attribute, fields) {
    for (let field of fields) {
      if (field.name == attribute && field.type)
        return field.type
    }
    return "esriFieldTypeString"
  }

}