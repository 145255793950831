/**
 * @module
 */

import DetailsHandlerDef from "../../../details/DetailsHandlerDef.js"
import icons from "../../../resources/icons.js"
import DawaSearcher from "../../DawaSearcher.js"
/**
 * @sspath Septima.Search
 */
export default class CvrInfoProvider extends DetailsHandlerDef {
  constructor(options = {}) {
    if (options.logger)
      options.logger.warn("CvrInfoProvider is deprecated - Use Septima.Search.DataApi.")
    else
      
      if (console && console.warn) {
        try{
          
          console.warn("CvrInfoProvider is deprecated - Use Septima.Search.DataApi.")
          // eslint-disable-next-line no-empty
        } catch(e) {}
      }

    let defaultOptions = {
      buttonText: "CVR-info",
      buttonImage: icons.searchers.cvr,
      komnumre: null,
      isApplicable: (result) => {
        return ((result.source === "cvr" && result.typeId === "produktionsenhed") || (result.source === "cvr" && result.typeId === "virksomhed"))
      }
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler

    this.fields = ["hovedbranche", "navn", "adresse_result", "email", "tlf", "cvrlink", "virksomhed_navn", "virksomhed_cvrlink", "virksomhed_enhedsliste"]
    if (typeof options.fields !== 'undefined')
      this.fields = options.fields

    if (typeof options.virksomhed_enhedsliste_komnumre !== 'undefined')
      this.komnumre = "" + options.virksomhed_enhedsliste_komnumre

    this.dawaSearcher = new DawaSearcher({})
  }

  async myHandler() {
    let items = []
    return items
  }
  
}