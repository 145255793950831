/**
 * @module
 */

import DetailsHandlerDef from "../../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../../details/DetailItemsList.js"

/**
 * Viser diverse arealer for et jordstykke
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 *   _type: datafordeler.ArealerForJordstykkeProvider
 *   _options:
 *     fetcher:
 *       _type: datafordeler.Fetcher
 *       _options:
 *         ...
 * @api
 **/
export default class ArealerForJordstykkeProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.buttonText=Arealer] Titel
   * @param {Object} options.fetcher datafordeler.Fetcher instance
   **/
  constructor(options= {}) {
    if (options.logger)
      options.logger.warn("ArealerForJordstykkeProvider er deprecated. brug Septima.Search.JordStykkeInfoProvider i stedet")
    else
      
      if (console && console.warn) {
        try{
          
          console.warn("ArealerForJordstykkeProvider er deprecated. brug Septima.Search.JordStykkeInfoProvider i stedet")
          // eslint-disable-next-line no-empty
        } catch(e) {}
      }

    if (!options.fetcher)
      throw new Error("JordstykkeInfoProvider called without options (Needs options.fetcher)")
    
    let defaultOptions = {
      buttonText: "Arealer"
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler
    this.isApplicableFunction = (result) => {
      return ((result.source === "Kortforsyningen" && result.typeId === "matrikelnumre"))
    }
    this.dafFetcher = options.fetcher
  }
  async myHandler(result) {
    let source = result.source
    let typeId = result.typeId
    if (source === "Kortforsyningen" && typeId === "matrikelnumre") {
      let ejerlavskode = result.data.elavskode
      let matrikelnr = result.data.matrnr
      let dafJordstykke = await this.dafFetcher.read("matrikel", "matrikel", "jordstykke", {Ejerlavskode: ejerlavskode, Matrikelnr: encodeURIComponent(matrikelnr)}, this.getLogger())
      if (dafJordstykke)
        return await this.getArealDetails(dafJordstykke)
    }
  }

  async getArealDetails(fokusJordStykke) {
    // 
    let eksempelJordstykke = {
      "type": "Jordstykke",
      "properties": {
        "arealberegningsmetode": "Areal beregnet efter opmåling - o",
        "arealbetegnelse": null,
        "arealtype": null,
        "brugsretsareal": false,
        "centroide": [
          {
            "geometri": "POINT(687507.577 6071959.899)",
            "senesteSagLokalId": "7764108",
            "status": "Gældende"
          }
        ],
        "delnummer": null,
        "ejerlavskode": 521455,
        "ejerlavsnavn": "Sdr. Vedby By, Nykøbing F.",
        "faelleslod": false,
        "forretningshaendelse": "Afledt ændring",
        "sekundaerForretningshaendelse": [],
        "forretningsomraade": "52.20.05",
        "forretningsproces": "Anden sagskategori",
        "fredskov": {
          "fredskovsareal": null,
          "omfang": null
        },
        "id_lokalId": "1279873",
        "id_namespace": "http://data.gov.dk/Matriklen/Jordstykke",
        "jordrente": {
          "omfang": null
        },
        "klitfredning": {
          "klitfredningsareal": null,
          "omfang": null
        },
        "kommuneLokalId": "0376",
        "majoratskov": {
          "majoratskovsnummer": null,
          "omfang": null
        },
        "matrikelnummer": "1ab",
        "paataenktHandling": null,
        "regionLokalId": "1085",
        "registreretAreal": 1252,
        "registreringFra": "2018-06-03T07:18:13.526534+02:00",
        "registreringTil": null,
        "registreringsaktoer": "Matriklen",
        "samletFastEjendomBFEnummer": 3140901,
        "senesteSagLokalId": "7764108",
        "skelforretningssagsLokalId": null,
        "sognLokalId": "7578",
        "stammerFraJordstykkeLokalId": null,
        "status": "Gældende",
        "stormfald": [],
        "strandbeskyttelse": {
          "omfang": null,
          "strandbeskyttelsesareal": null
        },
        "supplerendeMaalingSagLokalId": null,
        "vandarealinkludering": "ukendt",
        "vejareal": "0",
        "vejarealberegningsstatus": "vejareal beregnet",
        "virkningFra": "1977-03-22T00:00:00.000000+01:00",
        "virkningTil": null,
        "virkningsaktoer": "Geodatastyrelsen"
      }
    }

    let detailItemsList = new DetailItemsList({
      itemType: "labelvalue",
      header: "Arealer",
      name: "arealer",
      infoItemsHeaders: [
        {type: "labelvalue", label: "Areal (m2)", valueformat: "int"},
        {type: "labelvalue", label: "Note"}]
    })
    let registreretAreal = fokusJordStykke.properties.registreretAreal ? fokusJordStykke.properties.registreretAreal : null
    let registreretArealNote = fokusJordStykke.properties.arealberegningsmetode ? fokusJordStykke.properties.arealberegningsmetode : ""
    detailItemsList.append({
      type: "labelvalue",
      label: "Type",
      value: "Registreret",
      infoItems: [
        {type: "labelvalue", value: registreretAreal, valueformat: "int"},
        {type: "labelvalue", value: registreretArealNote}]
    })
    let vejAreal = fokusJordStykke.properties.vejareal ? fokusJordStykke.properties.vejareal : null
    let vejArealNote = fokusJordStykke.properties.vejarealberegningsstatus ? fokusJordStykke.properties.vejarealberegningsstatus : ""
    detailItemsList.append({
      type: "labelvalue",
      label: "Type",
      value: "Vej",
      infoItems: [
        {type: "labelvalue", value: vejAreal, valueformat: "int"},
        {type: "labelvalue", value: vejArealNote}]
    })
    let fredskovAreal = (fokusJordStykke.properties.fredskov && fokusJordStykke.properties.fredskov.fredskovsareal) ? fokusJordStykke.properties.fredskov.fredskovsareal : null
    let fredskovArealNote
    if (fredskovAreal === null)
      fredskovArealNote = "(Ukendt)"
    else
      fredskovArealNote = (fokusJordStykke.properties.fredskov && fokusJordStykke.properties.fredskov.omfang) ? fokusJordStykke.properties.fredskov.omfang : ""
    detailItemsList.append({
      type: "labelvalue",
      label: "Type",
      value: "Fredskov",
      infoItems: [
        {type: "labelvalue", value: fredskovAreal, valueformat: "int"},
        {type: "labelvalue", value: fredskovArealNote}]
    })
    let vandarealinkluderingNote = fokusJordStykke.properties.vandarealinkludering ? fokusJordStykke.properties.vandarealinkludering : ""
    detailItemsList.append({
      type: "labelvalue",
      label: "Type",
      value: "Vand",
      infoItems: [
        {type: "labelvalue", value: null},
        {type: "labelvalue", value: vandarealinkluderingNote}]
    })
    
    return [detailItemsList.asItem()]
  }
}