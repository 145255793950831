/**
 * @module
 */

import icons from "../resources/icons.js"
import jsonpath from "../util/JsonParser.js"

/**
 * Decorates a result
 * @example <caption>YAML Declaration:</caption>
   dawa:
    _type: Septima.Search.DawaSearcher
    _options:
      minimumShowCount: 3
      resultTypes:
        adresse:
          singular: Adresse
          plural: Adresser
          iconURI: data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTQsOUgxOS41TDE0LDMuNVY5TTcsMkgxNUwyMSw4VjIwQTIsMiAwIDAsMSAxOSwyMkg3QzUuODksMjIgNSwyMS4xIDUsMjBWNEEyLDIgMCAwLDEgNywyTTExLjkzLDEyLjQ0QzEyLjM0LDEzLjM0IDEyLjg2LDE0LjA4IDEzLjQ2LDE0LjU5TDEzLjg3LDE0LjkxQzEzLDE1LjA3IDExLjgsMTUuMzUgMTAuNTMsMTUuODRWMTUuODRMMTAuNDIsMTUuODhMMTAuOTIsMTQuODRDMTEuMzcsMTMuOTcgMTEuNywxMy4xOCAxMS45MywxMi40NE0xOC40MSwxNi4yNUMxOC41OSwxNi4wNyAxOC42OCwxNS44NCAxOC42OSwxNS41OUMxOC43MiwxNS4zOSAxOC42NywxNS4yIDE4LjU3LDE1LjA0QzE4LjI4LDE0LjU3IDE3LjUzLDE0LjM1IDE2LjI5LDE0LjM1TDE1LDE0LjQyTDE0LjEzLDEzLjg0QzEzLjUsMTMuMzIgMTIuOTMsMTIuNDEgMTIuNTMsMTEuMjhMMTIuNTcsMTEuMTRDMTIuOSw5LjgxIDEzLjIxLDguMiAxMi41NSw3LjU0QzEyLjM5LDcuMzggMTIuMTcsNy4zIDExLjk0LDcuM0gxMS43QzExLjMzLDcuMyAxMSw3LjY5IDEwLjkxLDguMDdDMTAuNTQsOS40IDEwLjc2LDEwLjEzIDExLjEzLDExLjM0VjExLjM1QzEwLjg4LDEyLjIzIDEwLjU2LDEzLjI1IDEwLjA1LDE0LjI4TDkuMDksMTYuMDhMOC4yLDE2LjU3QzcsMTcuMzIgNi40MywxOC4xNiA2LjMyLDE4LjY5QzYuMjgsMTguODggNi4zLDE5LjA1IDYuMzcsMTkuMjNMNi40LDE5LjI4TDYuODgsMTkuNTlMNy4zMiwxOS43QzguMTMsMTkuNyA5LjA1LDE4Ljc1IDEwLjI5LDE2LjYzTDEwLjQ3LDE2LjU2QzExLjUsMTYuMjMgMTIuNzgsMTYgMTQuNSwxNS44MUMxNS41MywxNi4zMiAxNi43NCwxNi41NSAxNy41LDE2LjU1QzE3Ljk0LDE2LjU1IDE4LjI0LDE2LjQ0IDE4LjQxLDE2LjI1TTE4LDE1LjU0TDE4LjA5LDE1LjY1QzE4LjA4LDE1Ljc1IDE4LjA1LDE1Ljc2IDE4LDE1Ljc4SDE3Ljk2TDE3Ljc3LDE1LjhDMTcuMzEsMTUuOCAxNi42LDE1LjYxIDE1Ljg3LDE1LjI5QzE1Ljk2LDE1LjE5IDE2LDE1LjE5IDE2LjEsMTUuMTlDMTcuNSwxNS4xOSAxNy45LDE1LjQ0IDE4LDE1LjU0TTguODMsMTdDOC4xOCwxOC4xOSA3LjU5LDE4Ljg1IDcuMTQsMTlDNy4xOSwxOC42MiA3LjY0LDE3Ljk2IDguMzUsMTcuMzFMOC44MywxN00xMS44NSwxMC4wOUMxMS42Miw5LjE5IDExLjYxLDguNDYgMTEuNzgsOC4wNEwxMS44NSw3LjkyTDEyLDcuOTdDMTIuMTcsOC4yMSAxMi4xOSw4LjUzIDEyLjA5LDkuMDdMMTIuMDYsOS4yM0wxMS45LDEwLjA1TDExLjg1LDEwLjA5WiIgLz48L3N2Zz4=
    detailhandlers:
      - _type: Septima.Search.InfoForSognProvider
        _options:
          more: false
          buttonText: Ligger i sogn
          proxy:
            item:
              type: result
              label: Ligger i sogn
              show: true
            result:
              searcher: 
                _ref: $.mysearchers.geosearcher
              typeId: sogne
              id: $.properties.sogn.kode
 * @example <caption>YAML Declaration: (SqDetailsHandler fires for results of type produktionsenhed)</caption>
  cvr:
    _type: Septima.Search.DataApi.CvrSearcher
    _options:
      fetcher:
        _type: Septima.Search.DataApi.Fetcher
    detailhandlers:
      - _type: Septima.Search.SqDetailsHandler
        _options:
          buttonText: Planer for ejende virksomhed
          proxy:
            item:
              type: result
              label: Ejende virksomhed
              show: true
            result:
              searcher:
                _ref: $.mysearchers.cvr
              typeId: virksomhed
              id: produktionsenhed->$.cvrnummer
        searchers:
          - _ref: "$.mysearchers.planSystemSearcher"
 * @api
 */
export default class DetailsHandlerDef {

  /**
   * @param {Object} options
   * @param {Object} [options.proxy] Indites whether a proxy for the result should be used
   * @param {Object} [options.proxy.item] Describes how to present the found proxy
   * @param {Object} [options.proxy.item.label]
   * @param {Object} [options.proxy.item.type=result] result|labelvalue
   * @param {Object} [options.proxy.item.show=true] should the found proxy be shown
   * @param {Object} options.proxy.result Describes how to find the proxyResult
   * @param {Object} options.proxy.result.searcher which searcher to use
   * @param {Object} options.proxy.result.typeId which type of result
   * @param {Object} options.proxy.result.id [mainresult.type->] id of proxy result. "$." is replaced with result.data property values (see examples)
   * @param {boolean} [options.more=true] Indicates whether the details provided should be considered an extension of the result rather than related information (Layouts may show "more" handlers at the top without specific heading)
   * @param {boolean} [options.targets=[{source: '*', typeId: '*'}] Arrayof Combinations of source/typeIds that are supported by the handler
   * @param {string} [options.buttonText=""] Title of the handler (Layouts may show the title as tab caption)
   * @param {string} [options.buttonImage=icons.infoGrey] Url to icon of the handler (Layouts may show the image as part of the tab caption)
   * @param {string} [options.meta] Description (e.g metadata of output) of the handler (A Detail Itens)
   * @param {Object} [options.logger]
   * @param {string} [options.logLevel]
   * @param {string} [options.id=random unique string]
   * @param {function} [options.isApplicableFunction=check against options.targets] function, (result)=>, return boolean indicating whether the result is handled
   * @param {function} options.handler function, (result)=>, return detail items
   * @param {function} options.meta
   **/
  constructor(options= {}) {

    this.more = false
    this.targets = [{source: '*', typeId: '*'}]
    this.buttonImage = icons.infoGrey
    this.meta = null
    this.id = "DetailsHandler" + "_" + Math.floor(Math.random() * 999999999)
    this.buttonText = this.id
    this.detailHandlerDefs = []
    this.handlerFunction = async()=>{
      return[]
    }

    this.isApplicableFunction = (result)=>{
      return this.hasTarget(result.source, result.typeId)
    }

    if (options.targets) {
      this.targets = []
      for (let target of options.targets) {
        if (typeof target.source == 'undefined' || target.source === null)
          target.source = "*"

        if (typeof target.typeId == 'undefined' || target.typeId === null) {
          this.targets.push({source: target.source, typeId: "*"})
        } else if(Array.isArray(target.typeId)) {
          for (let typeId of target.typeId)
            this.targets.push({source: target.source, typeId})
        } else {
          this.targets.push({source: target.source, typeId: target.typeId})
        }
      }
    }

    if (options.id) {
      this.id = options.id
      this.buttonText = this.id
    }

    if (options.more)
      this.more = options.more

    if (typeof options.buttonText !== 'undefined')
      this.buttonText = options.buttonText

    if (options.buttonImage)
      this.buttonImage = options.buttonImage

    if (options.meta)
      this.meta = options.meta

    if (options.handler)
      this.handlerFunction = options.handler

    if (options.isApplicable)
      this.isApplicableFunction = options.isApplicable

    if (options.renderHints)
      this.renderHints = options.renderHints

    if (options.detailhandlers)
      this.detailhandlers = options.detailhandlers
    
    if (options.proxy) {
      this.proxy = options.proxy
      //this.targets.push({source: '*', typeId: '*'})
      this.proxy.item = Object.assign({type: "result", show: true}, this.proxy.item)
    }

    if (options.logger) {
      let childBindings = {
        module: this.id + "(" + this.buttonText + ")"
      }
      let childOptions = {}
      if (options.logLevel)
        childOptions.level = options.logLevel
      this._logger = options.logger.child(childBindings, childOptions)
    }

  }

  hasTarget(source, typeId) {
    for (let target of this.targets) {
      if ((target.source === '*' || target.source.toLowerCase() === source.toLowerCase()) && (target.typeId === '*' || target.typeId.toLowerCase() === typeId.toLowerCase()))
        return true
    }
    return false
  }

  getId() {
    return this.id
  }

  set detailhandlers(dhArray) {
    for (let detailhandler of dhArray)
      this.addDetailHandlerDef(detailhandler)
  }

  /**
   *
   * @param {module:js/details/DetailsHandlerDef} detailHandlerDef
   * @param typeId
   */
  addDetailHandlerDef(detailHandlerDef) {
    this.detailHandlerDefs.push(detailHandlerDef)
  }

  getLogger() {
    return this._logger
  }

  getbuttonText() {
    return this.buttonText
  }

  getbuttonImage() {
    return this.buttonImage
  }
  
  getMeta() {
    return this.meta
  }
  
  getButtonText() {
    return this.buttonText
  }
  
  getButtonImage() {
    return this.buttonImage
  }

  async _getProxyResult(result) {
    let searcher = this.proxy.result.searcher
    let type = this.proxy.result.typeId
    let id = this.proxy.result.id
    let idParts = id.split("->")
    if (idParts.length > 1) {
      let requiredType = idParts[0]
      if (result.typeId != requiredType)
        return null
      id = idParts[1]
    }
    if (id.indexOf("$.") == 0)
      id = jsonpath.value(result.data, id)
    if (searcher && id)
      return searcher.get(id, type)
  }
  
  async handler(result, renderAsMore, contextResult) {
    let detailItems = []
    let resultToDecorate = result
    let logger = this.getLogger()
    try {
      if (this.proxy) {
        resultToDecorate = await this._getProxyResult(result)
        if (resultToDecorate) {
          if (this.proxy.item.show) {
            let proxyItem
            if (this.proxy.item.type == "result") {
              proxyItem = {
                type: "result",
                result: resultToDecorate
              }
            } else {
              proxyItem = {
                type: "labelvalue",
                value: resultToDecorate.title
              }
            }
            if (this.proxy.item.label)
              proxyItem.label = this.proxy.item.label
            detailItems.push(proxyItem)
          }
        } else {
          return []
        }
      }

      detailItems = [...detailItems, ...await this.handleThisOrRelated(resultToDecorate, renderAsMore, contextResult)]
      if (this.detailHandlerDefs.length>0)
        for (let item of detailItems)
          if (item.type === "result")
            await this.decorateResultItem(item, resultToDecorate)
          else if (item.type === "list" && item.itemType === 'result' && item.items.length > 0)
            await this.decorateResultList(item, resultToDecorate)
      if (logger) {
        logger.trace({function:'handler', result: resultToDecorate.summary(), detailItems}, "handled")
        if (logger.isLevelEnabled && !logger.isLevelEnabled("trace"))
          logger.debug({function:'handler', result: resultToDecorate.summary()}, "handled")
      }
      return detailItems
    }catch(e) {
      if (logger)
        logger.error({function:'handler', result: resultToDecorate.summary(), exception: e}, "Exception")
      throw e
    }
  }

  async handleThisOrRelated(result, renderAsMore, contextResult) {
    let logger = this.getLogger()

    if (this._canHandle(result, contextResult)) {
      return await this._handle(result, renderAsMore, logger, contextResult)
    } else {
      //Try parents
      let relations = await result.getRelations()
      let parentResults = relations.parents
      for (let parentResult of parentResults)
        if (this._canHandle(result, contextResult))
          return await this._handle(parentResult, renderAsMore, logger, contextResult)
    }
    return []
  }

  async _handle(result, renderAsMore, logger, contextResult) {
    let handlerResult
    if (typeof renderAsMore !== 'undefined' && renderAsMore !== this.more) {
      this.more = !this.more
      handlerResult = await this.handlerFunction(result, logger, contextResult)
      this.more = !this.more
    }else {
      handlerResult = await this.handlerFunction(result, logger, contextResult)
    }
    return handlerResult
  }


  async decorateResultItem(resultItem, contextResult) {
    for (let detailHandlerDef of this.detailHandlerDefs)
      if (detailHandlerDef.isApplicable(resultItem.result, contextResult)) {
        let detailItems = await detailHandlerDef.handler(resultItem.result, false, contextResult)
        let infoItemsHeaders = this.extractItemHeaders(detailItems)
        resultItem.infoItemsHeaders = infoItemsHeaders
        resultItem.infoItems = detailItems
        break
      }
  }

  async decorateResultList(resultList, contextResult) {
    let firstResultItem = resultList.items[0]
    for (let detailHandlerDef of this.detailHandlerDefs)
      if (detailHandlerDef.isApplicable(firstResultItem.result, contextResult)) {
        try {
          let firstDetailItemsPromise = detailHandlerDef.handler(firstResultItem.result, false, contextResult)
          let otherDetailItemsPromises = []
          let todoArray = []
          for (let j = 1; j < resultList.items.length; j++) {
            let resultItem = resultList.items[j]
            let detailItemsPromise = detailHandlerDef.handler(resultItem.result, false, contextResult)
            otherDetailItemsPromises.push(detailItemsPromise)
            todoArray.push({resultItem, detailItemsPromise})
          }

          let firstDetailItems = await firstDetailItemsPromise
          let infoItemsHeaders = this.extractItemHeaders(firstDetailItems)
          resultList.infoItemsHeaders = infoItemsHeaders
          firstResultItem.infoItems = firstDetailItems

          await Promise.all(otherDetailItemsPromises)
          for (let todo of todoArray)
            todo.resultItem.infoItems = await todo.detailItemsPromise

          break
        } catch(e) {
          
          console.log(e)
        }

      }
  }

  extractItemHeaders(items) {
    let headerItems = []
    for (let item of items)
      if (item.type === "result") {
        let result = item.result
        headerItems.push({
          type: "result",
          label: item.label ? item.label : result.type.singular 
        })
      } else if(item.type === "labelvalue") {
        headerItems.push({
          type: "labelvalue",
          label: item.label,
          valueformat: item.valueformat
        })
      } else if (item.type === "link") {
        headerItems.push({
          type: "link",
          label: item.value
        })
      }else if (item.type === "image") {
        headerItems.push({
          type: "image"
        })
      } else if (item.type === "list") {
        headerItems.push({
          type: "list",
          label: item.header ? item.header : "" 
        })
      }
    return headerItems
  }

  isApplicable(result, contextResult) {
    if (this.proxy) {
      let id = this.proxy.result.id
      let idParts = id.split("->")
      if (idParts.length > 1) {
        let requiredType = idParts[0]
        if (result.typeId != requiredType)
          return false
        id = idParts[1]
      }
      if (id.indexOf("$.") == 0) {
        id = jsonpath.value(result.data, id)
        if (!id)
          return false
      }
      return true
    } else {
      return this._canHandle(result, contextResult)
    }
  }
  
  _canHandle(result, contextResult) {
    return (this.hasTarget(result.source, result.typeId) && this.isApplicableFunction(result, contextResult))
  }

  addToSearchers(searchers) {
    searchers.forEach(searcher => searcher.addDetailHandlerDef(this))
  }
}