/**
 * @module
 */
import Searcher from "../Searcher.js"
import ResultType from "../../ResultType.js"
import icons from "../../resources/icons.js"
import * as reproject from "../../util/reproject.js"
/**
 * CVR-søgning mod DataApi-postgrest
 * @extends module:js/searchers/Searcher
 * @example <caption>YAML Declaration:</caption>
 *   _type: Septima.Search.DataApi.CvrSearcher
 *   _options:
 *     fetcher:
 *       _type: Septima.Search.DataApi.Fetcher
 *       _options:
 *         token: tttttttttttttt
 *         token: tttttttttttttt
 *         
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
  var redataFetcher = new Septima.Search.DataApi.Fetcher({
    token: "...."
  })
  
  var cvrSearcher = new Septima.Search.DataAPi.CvrSearcher({
    fetcher: redataFetcher,
    goal: "*",
    kommunekode: "0101"
  });
  controller.addSearcher(cvrSearcher);
 * @sspath Septima.Search.DataApi
 * **/
export default class CvrSearcher extends Searcher {
  //https://www.dst.dk/klassifikationsbilag/380b62f0-da15-4117-8708-0f319e8f6aad
  /**
   *
   * @param {Object} options CvrSearcher expects these options:
   * @param {Object} options.fetcher Septima.Search.DataApi.Fetcher instance
   * @param {boolean} [options.er_aktiv=true]  Hvilke skal medtages? Muligheder true, false, "*"
   * @param {string} [options.kommunekode='*']  "*" Search all municipalities (Default)</br>Search specific municipalities eg. "0101" or "0101 0256"
   * @param {string} [options.hovedafdelingkode='*'] CVR hovedafdelingkode. Codes from bottom in hierarchy are used. If branchekode is present and not "*" it is used even if hovedafdelingkode is present  (https://www.dst.dk/da/Statistik/dokumentation/nomenklaturer/db07)
   * @param {string} [options.hovedgruppekode='*'] CVR hovedgruppekode
   * @param {string} [options.gruppekode='*'] CVR gruppekode
   * @param {string} [options.undergruppekode='*'] CVR undergruppekode
   * @param {string} [options.branchekode='*'] CVR branchekode
   * @param {string} [options.cvrnummer='*'] CVR numre f.eks.: '26259495 34900841' (bliver kun brugt i søgning på p-enheder)
   * @param {string} [options.goal="*"] virksomhed, produktionsenhed, * What to search for 
   *  
   * @api
   */
  constructor(options) {
    if (!options.fetcher)
      throw new Error("CvrSearcher expects options.fetcher")

    let defaultOptions = {
      usesGeoFunctions: true,
      defaultCrs: "25832",
      kommunekode: "*",
      hovedafdelingkode: "*",
      hovedgruppekode: "*",
      gruppekode: "*",
      undergruppekode: "*",
      branchekode: "*",
      cvrnummer: "*",
      goal: "*"
    }
    
    let finalOptions = Object.assign(defaultOptions, options)
    super(finalOptions)
    this.er_aktiv = [true]
    if (typeof options.er_aktiv != 'undefined')
      if (options.er_aktiv == '*')
        this.er_aktiv = [true, false]
      else if (options.er_aktiv == false)
        this.er_aktiv = [false]

    this.kommunekode = "" + finalOptions.kommunekode
    this.hovedafdelingkode = "" + finalOptions.hovedafdelingkode
    this.hovedgruppekode = "" + finalOptions.hovedgruppekode
    this.gruppekode = "" + finalOptions.gruppekode
    this.undergruppekode = "" + finalOptions.undergruppekode
    this.branchekode = "" + finalOptions.branchekode
    this.branchekode = "" + finalOptions.branchekode
    this.cvrnummer = "" + finalOptions.cvrnummer
    this.source = "cvr"

    this.fetcher = options.fetcher

    this.cvrNrType = new ResultType({
      id: "virksomhed",
      geometrySupport: "sq",
      queryBehaviour: this.goal == "produktionsenhed" ? "none" : "search",
      singular: "Virksomhed",
      plural: "Virksomheder",
      iconURI: icons.searchers.cvr,
      itemFunction: this.addCvrnrItemToQueryResult.bind(this)
    })

    this.pNrType = new ResultType({
      id: "produktionsenhed",
      geometrySupport: "sq",
      queryBehaviour: this.goal == "virksomhed" ? "none" : "search",
      singular: "Produktionsenhed",
      plural: "Produktionsenheder",
      iconURI: icons.searchers.cvr,
      itemFunction: this.addPnrItemToQueryResult.bind(this)
    })

    this.registerType(this.source, this.cvrNrType)
    this.registerType(this.source, this.pNrType)
  }

  async asyncFetchData(query) {
    switch (query.type) {
      case 'collapse': {
        return this.createNewQueriesFromGoal(query)
      }
      case 'cut':
      case 'no-cut':
      case 'list': {
        let queryResult = this.createQueryResult()
        let results
        try {
          results = await this.queryDataApi(query)
        } catch(error) {
          let logger = this.getLogger()
          if (logger)
            logger.error("Error in CvrSearcher.asyncFetchData: " + error)
          return results
        }
        
        if (results) {
          if (results.virksomheder && results.virksomheder.length > 0)
            this.addItemsToQueryResult(results.virksomheder, this.cvrNrType, query, queryResult)
          if (results.produktionsenheder && results.produktionsenheder.length > 0)
            this.addItemsToQueryResult(results.produktionsenheder, this.pNrType, query, queryResult)
        }
        return queryResult
      }
    }
  }

  addItemsToQueryResult(items, resultType, query, queryResult) {
    let count = items.length
    if (count > 0 && items[0]["hits_found"] && items[0]["hits_found"] != 9999)
      count = items[0]["hits_found"]
    if (["list", "cut", "no-cut"].indexOf(query.type) !== -1) {
      let hitsShown = (count === 1) ? 1 : (query.type === 'no-cut' && count > query.limit) ? 0 : Math.min(count, query.limit)
      for (let item of items.slice(0, hitsShown)) {
        resultType.itemFunction(item, queryResult)
      }
      if (count > hitsShown && ["no-cut", "cut"].indexOf(query.type) !== -1) {
        let newQuery = queryResult.addNewQuery(this.source, resultType.id, resultType.plural, null, query.queryString, null, null)
        if (typeof resultType.iconURI !== 'undefined')
          newQuery.image = resultType.iconURI
        else if (typeof this.iconURI !== 'undefined')
          newQuery.image = this.iconURI
      }
    } else {
      if (count > 0) {
        let newQuery = queryResult.addNewQuery(this.source, resultType.id, resultType.plural, null, query.queryString, null, null)
        if (typeof resultType.iconURI !== 'undefined')
          newQuery.image = resultType.iconURI
        else if (typeof this.iconURI !== 'undefined')
          newQuery.image = this.iconURI
      }
    }
  }

  addCvrnrItemToQueryResult(item, queryResult = this.createQueryResult()) {
    if (item) {
      let geometry = item.geometri
      if (geometry)
        geometry.crs = {
          "type": "name",
          "properties": {
            "name": "epsg:25832"
          }
        }
      let description = item.adresse ? item.adresse+' ('+item.branche+')' : item.branche
      let result = queryResult.addResult(this.source, this.cvrNrType.id, item.navn, description, geometry, item)
      result.id = item.cvrnummer
      result.description = item.branche
      result.image = this.cvrNrType.iconURI
      return result
    }
  }

  addPnrItemToQueryResult(item, queryResult = this.createQueryResult()) {
    if (item) {
      let geometry = item.geometri
      if (geometry)
        geometry.crs = {
          "type": "name",
          "properties": {
            "name": "epsg:25832"
          }
        }

      let description = item.adresse ? item.adresse+' ('+item.branche+')' : item.branche

      let result = queryResult.addResult(this.source, this.pNrType.id, item.navn, description, geometry, item)
      if (!item.adresse)
        result.isComplete = false
      result.id = item.pnummer

      result.image = this.pNrType.iconURI
      return result
    }
  }

  async completeResult(result) {
    if (result.isComplete) {
      return result
    } else {
      if (result.typeId == this.pNrType.id) {
        return this.get(result.id, this.pNrType.id)
      }
      result.isComplete = true
      return result
    }
  }

  createNewQueriesFromGoal(query) {
    let queryResult = this.createQueryResult()
    if (query.hasTarget ? query.target.type == this.cvrNrType.id : this.goal != this.pNrType.id)
      queryResult.addNewQuery(this.source, this.cvrNrType.id, this.cvrNrType.plural, null, query.queryString)
    if (query.hasTarget ? query.target.type == this.pNrType.id : this.goal != this.cvrNrType.id)
      queryResult.addNewQuery(this.source, this.pNrType.id, this.pNrType.plural, null, query.queryString)
    return queryResult
  }

  async sq(query) {
    let queryResult = this.createQueryResult()
    if (query.geometry) {
      let queryGeometry = reproject.reproject(query.geometry, null, "EPSG:25832")

      let params = this.getParams(queryGeometry)
      let filter = this.getFilterFromParams(params)

      let virksomhedsPromise = Promise.resolve([])
      if (query.hasTarget ? query.target.type == this.cvrNrType.id : this.goal != this.pNrType.id)
        virksomhedsPromise = this.fetcher.search("virksomhed_search", "", null, filter).catch((error) => {
          throw error
        })
      
      filter = Object.assign({}, filter, this.getCvrFilter())

      let produktionsenhedsPromise = Promise.resolve([])
      if (query.hasTarget ? query.target.type == this.pNrType.id : this.goal != this.cvrNrType.id)
        produktionsenhedsPromise = this.fetcher.search("produktionsenhed_search", "", null, filter).catch((error) => {
          throw error
        })

      await Promise.all([virksomhedsPromise, produktionsenhedsPromise])

      let virksomhedsItems = await virksomhedsPromise
      if (virksomhedsItems.length > 0)
        this.addItemsToQueryResult(virksomhedsItems, this.cvrNrType, { type: "list", limit: 500 }, queryResult)

      let prroduktionsenhederItems = await produktionsenhedsPromise
      if (prroduktionsenhederItems.length > 0)
        this.addItemsToQueryResult(prroduktionsenhederItems, this.pNrType, { type: "list", limit: 500 }, queryResult)
    }
    return queryResult
  }

  getParams(geometry) {
    let params = {}

    params.er_aktiv = this.er_aktiv

    if (geometry)
      params.geometry = geometry

    if (this.kommunekode != "*") {
      let kommunekoder = this.parseCodes(this.kommunekode)
      kommunekoder = this.prependZero(kommunekoder)
      params.kommunekoder = kommunekoder
    }
    if (this.hovedafdelingkode != "*") {
      let spacedHovedafdelingkode = this.parseCodes(this.hovedafdelingkode)
      params.hovedafdelingkode = spacedHovedafdelingkode
    }
    if (this.hovedgruppekode != "*") {
      let spacedHovedgruppekode = this.parseCodes(this.hovedgruppekode)
      params.hovedgruppekode = spacedHovedgruppekode
    }
    if (this.gruppekode != "*") {
      let spacedGruppekode = this.parseCodes(this.gruppekode)
      params.gruppekode = spacedGruppekode
    }
    if (this.undergruppekode != "*") {
      let spacedUndergruppekode = this.parseCodes(this.undergruppekode)
      params.undergruppekode = spacedUndergruppekode
    }

    if (this.branchekode != "*") {
      let spacedBranchekode = this.parseCodes(this.branchekode)
      params.branchekode = spacedBranchekode
    }
    return params
  }
  
  parseCodes(codes) {
    let spacedcodes = codes.replace(/,/g, " ").replace(/\|/, " ")
    return spacedcodes.split(" ")
  }

  getFilterFromParams(params) {
    let filter = {}
    filter['er_aktiv'] = params.er_aktiv

    if (params.kommunekoder)
      filter['kommuner'] = params.kommunekoder
    if (params.hovedafdelingkode)
      filter['branche_hovedafdelinger'] = params.hovedafdelingkode
    if (params.hovedgruppekode)
      filter['branche_hovedgrupper'] = params.hovedgruppekode
    if (params.gruppekode)
      filter['branche_grupper'] = params.gruppekode
    if (params.undergruppekode)
      filter['branche_undergrupper'] = params.undergruppekode
    if (params.branchekode)
      filter['branchekode'] = params.branchekode
    if (params.geometry)
      filter['geometri'] = params.geometry

    return filter
  }

  getCvrFilter() {
    let filter = {}
    if (this.cvrnummer !== "*") {
      filter['cvrnummer'] = this.parseCodes(this.cvrnummer)
    }
    return filter
  }
  
  async queryDataApi(query) {
    try {
      let queryString = query.queryString
      if (query.isBlank)
        queryString = "a"
      
      let params = this.getParams()
      let filter = this.getFilterFromParams(params)

      let virksomhedsPromise = Promise.resolve([])
      if (query.hasTarget ? query.target.type == this.cvrNrType.id : this.goal != this.pNrType.id) {
        virksomhedsPromise = this.fetcher.search("virksomhed_search", queryString, query.limit + 2, filter, this.getLogger())
      }

      filter = Object.assign({}, filter, this.getCvrFilter())
      let produktionsenhedsPromise = Promise.resolve([])
      if (query.hasTarget ? (query.target.type == "produktionsenhed") : (this.goal != "virksomhed")) {

        produktionsenhedsPromise = this.fetcher.search("produktionsenhed_search", queryString, query.limit + 2, filter, this.getLogger())
      }
      await Promise.all([virksomhedsPromise, produktionsenhedsPromise])

      return { virksomheder: await virksomhedsPromise, produktionsenheder: await produktionsenhedsPromise }
    } catch (e) {
      throw new Error(e)
    }
  }

  async get(id, typeId) {
    if (typeId == this.cvrNrType.id) {
      let items = await this.fetcher.get("virksomhed_detaljer", { cvrnummer: "eq." + id, select: "*,branchekode:branche(branchekode,undergruppekode:branche_undergruppe(*,gruppekode:branche_gruppe(*,hovedgruppekode:branche_hovedgruppe(*,hovedafdelingkode:branche_hovedafdeling(*)))))" }, this.getLogger())
      return this.addCvrnrItemToQueryResult(items[0])                                             
    } else if (typeId == this.pNrType.id) {
      let items = await this.fetcher.get("produktionsenhed_detaljer", { pnummer: "eq." + id, select: "*,branchekode:branche(branchekode,undergruppekode:branche_undergruppe(*,gruppekode:branche_gruppe(*,hovedgruppekode:branche_hovedgruppe(*,hovedafdelingkode:branche_hovedafdeling(*)))))" }, this.getLogger())
      return this.addPnrItemToQueryResult(items[0])
    }
    return null
  }

  async getVirksomhederForHusnummer(hId) {
    let queryResult = this.createQueryResult()
    let items = await this.fetcher.get("virksomhed", { husnummerid: "eq." + hId }, this.getLogger())
    for (let item of items)
      this.addCvrnrItemToQueryResult(item, queryResult)
    return queryResult
  }

  async getProduktionsenhederForHusnummer(hId) {
    let queryResult = this.createQueryResult()
    let items = await this.fetcher.get("produktionsenhed", { husnummerid: "eq." + hId }, this.getLogger())
    for (let item of items)
      this.addPnrItemToQueryResult(item, queryResult)
    return queryResult
  }

  async getProduktionsEnhederForCvrNummer(cvrNummer, komnumre, er_aktiv) {
    let queryResult = this.createQueryResult()
    let params = { cvrnummer: "eq." + cvrNummer }
    if (komnumre) {
      //Fix for different separators
      let spacedKomnumre = ("" + komnumre).replace(/,/, " ").replace(/\|/, " ")
      let kommunekoder = spacedKomnumre.split(" ")
      kommunekoder = kommunekoder.filter(kode => kode.length > 1)
      kommunekoder = kommunekoder.map(kode => kode.length == 3 ? "0" + kode : kode)
      //&kommunekode=in.(0316, 0751)
      params.kommunekode = "in.(" + kommunekoder.join(",") + ")"
    }
    if (typeof er_aktiv !== 'undefined') {
      params.er_aktiv = "in.(" + er_aktiv + ")"
    }
    
    let items = await this.fetcher.get("produktionsenhed_detaljer", params, this.getLogger())
    for (let item of items)
      this.addPnrItemToQueryResult(item, queryResult)
    return queryResult
  }

  async getRegistreringerForHusnummer(hId, er_aktiv) {
    let registreringer = { virksomheder: null, produktionsenheder: null }
    if (this.goal != "produktionsenhed") {
      let queryResult = this.createQueryResult()
      let items = await this.fetcher.get("virksomhed_detaljer", { husnummerid: "eq." + hId, er_aktiv: "in.(" + er_aktiv + ")" }, this.getLogger())
      for (let item of items)
        this.addCvrnrItemToQueryResult(item, queryResult)
      registreringer.virksomheder = queryResult.getResults()
    }
    if (this.goal != "virksomhed") {
      let queryResult = this.createQueryResult()
      let items = await this.fetcher.get("produktionsenhed_detaljer", { husnummerid: "eq." + hId, er_aktiv: "in.(" + er_aktiv + ")" }, this.getLogger())
      for (let item of items)
        this.addPnrItemToQueryResult(item, queryResult)
      registreringer.produktionsenheder = queryResult.getResults()
    }
    return registreringer
  }

  prependZero(codes) {
    let parsed = codes.filter(kode => kode.length > 1)
    return parsed.map(kode => kode.length == 3 ? "0" + kode : kode)

  }
}