/**
 * @module
 */
import DetailsHandlerDef from "../../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../../details/DetailItemsList.js"

/**
 * Viser bygninger på jordstykke og husnummer/adresse
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 *   _type: datafordeler.BygningerForHusnummerJordstykke
 *   _options:
 *     bygningssearcher:
 *       _type: datafordeler.BygningsSearcher
 *       _options:
 *         ...
 * @api
 **/
export default class BygningerForHusnummerJordstykke extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.buttonText=Bygninger] Titel
   * @param {Object} options.bygningssearcher datafordeler.bygningssearcher
   **/
  constructor(options={}) {
    
    if (options.logger)
      options.logger.warn("BygningerForHusnummerJordstykke er deprecated. brug Septima.Search.Datafordeler.Bygninger i stedet")
    else
      
      if (console && console.warn) {
        try{
          
          console.warn("BygningerForHusnummerJordstykke er deprecated. brug Septima.Search.Datafordeler.Bygninger i stedet")
          // eslint-disable-next-line no-empty
        } catch(e) {}
      }
    
    if(!options.searcher)
      throw "BygningerForHusnummerJordstykke must be created with options.searcher"

    let defaultOptions = {
      buttonText: "Bygninger"
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler
    this.isApplicableFunction = (result) => {
      return (result.source === "Kortforsyningen" && result.typeId === "matrikelnumre") || (result.source === "Dawa" && result.typeId === "adresse")
    }

    this.searcher = options.searcher
    this.fetcher = options.searcher.fetcher
  }
  
  getbuttonText(result) {
    let typeId = result.typeId
    if (typeId === "adresse")
      return "Bygning"
    else if (typeId === "matrikelnumre")
      return "Bygninger"
    return "Bygninger"
  }
  async myHandler(result) {
    let source = result.source
    let typeId = result.typeId
    if (source === "Kortforsyningen" && typeId === "matrikelnumre")
      return await this.getBygningerForMatrikelNummerDetails(result)

    else if (source === "Dawa" && typeId === "adresse")
      return await this.getBygningerForHusnummerDetails(result)
  }

  async getBygningerForMatrikelNummerDetails(matResult) {
    let items = []
    let jordstykkeid = matResult.data.jordstykke_id ? matResult.data.jordstykke_id : matResult.data.lokalId

    let bygningerResponse = await this.fetcher.read("bbr", "bbr", "bygning", {jordstykke: jordstykkeid, Pagesize: 1000}, this.getLogger())
    bygningerResponse = bygningerResponse.filter(function (b) {
      return b.status === "3" || b.status === "6" || b.status === "12" || b.status === "13"
    })
    if (bygningerResponse && bygningerResponse.length > 0) {

      let bygIds = []
      for (let bygning of bygningerResponse) {
        let bygId = bygning.id_lokalId
        bygIds.push(bygId)
      }
      let bygResults = await this.searcher.gets(bygIds, "bygning")
      let detailItemsList = new DetailItemsList({
        itemType: "result",
        header: "" + bygResults.length + " Bygninger",
        name: "bygninger_liste"
      })
      for (let bygningsResult of bygResults) {
        detailItemsList.append({
          type: "result",
          result: bygningsResult
        })
      }
      items.push(detailItemsList.asItem())
    }
    if (items.length > 0) {
      return items
    } else {
      let detailItemsList = new DetailItemsList({
        itemType: "labelvalue",
        header: "Bygninger",
        name: "bygninger_ingen"
      })
      detailItemsList.append({
        type: "labelvalue",
        value: "Ingen bygninger registreret på jordstykket"
      })
      items.push(detailItemsList.asItem())
      return items
    }
  }

  async getBygningerForHusnummerDetails(dawaResult) {
    let detailItemsList = new DetailItemsList({
      itemType: "result",
      header: "Ligger i bygning",
      name: "bygninger_liste"
    })
    let items = []
    let husnummerId = dawaResult.data.properties.id
    let darHusnumreResponse = await this.fetcher.read("dar", "dar", "husnummer", {id: husnummerId}, this.getLogger())
    if (darHusnumreResponse.length > 0) {
      if (darHusnumreResponse[0].adgangTilBygning) {
        let bygnigsId = darHusnumreResponse[0].adgangTilBygning
        if (bygnigsId) {
          let bygningsResult = await this.searcher.get(bygnigsId, "bygning")
          if (bygningsResult) {
            detailItemsList.append({
              type: "result",
              result: bygningsResult
            })
            items.push(detailItemsList.asItem())
          }
        }
      }
    }
    if (items.length > 0) {
      return items
    } else {
      let detailItemsList = new DetailItemsList({
        itemType: "labelvalue",
        header: "Bygning",
        name: "bygninger_ingen"
      })
      detailItemsList.append({
        type: "labelvalue",
        value: "Husnummeret ligger ikke i en bygning"
      })
      items.push(detailItemsList.asItem())
      return items
    }
  }

}