/**
 * @module
 */
import DetailsHandlerDef from "../../../details/DetailsHandlerDef.js"
import DetailItemsList from "../../../details/DetailItemsList.js"


/**
 * Viser bygninger på samlet fast ejendom (daf.sfe)
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 *   _type: datafordeler.BygningerForEjendom
 *   _options:
 *     bygningssearcher:
 *       _type: datafordeler.BygningsSearcher
 *       _options:
 *         ...
 * @api
 **/
export default class BygningerForEjendom extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.buttonText=Bygninger] Titel
   * @param {Object} options.bygningssearcher datafordeler.bygningssearcher
   **/
  constructor(options= {}) {

    if (options.logger)
      options.logger.warn("BygningerForEjendom er deprecated. brug Septima.Search.Datafordeler.Bygninger i stedet")
    else
      
      if (console && console.warn) {
        try{
          
          console.warn("BygningerForEjendom er deprecated. brug Septima.Search.Datafordeler.Bygninger i stedet")
          // eslint-disable-next-line no-empty
        } catch(e) {}
      }
    
    let defaultOptions = {
      buttonText: "Bygninger"
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler
    this.isApplicableFunction = (result) => {
      let source = result.source
      let typeId = result.typeId
      return (source === "DAF" && typeId === "sfe")
    }

    this.bygningsSearcher = options.bygningssearcher

  }
  async myHandler(result) {
    let source = result.source
    let typeId = result.typeId
    if (source === "DAF" && typeId === "sfe")
      return await this.getBygningerItems(result)
  }

  async getBygningerItems(bfeResult) {
    //let bfeNummer = bfeResult.data.bfeResponse.properties.BFEnummer
    let detailItemsList = new DetailItemsList({
      itemType: "result",
      name: "bygninger_liste"
    })

    let bygResults = await this.bygningsSearcher.getBygningerForBfeNummer(bfeResult.id)
    for (let bygResult of bygResults) {
      detailItemsList.append({
        type: "result",
        result: bygResult
      })
    }

    if (bygResults.length > 0) {
      return [detailItemsList.asItem()]
    } else {
      let detailItemsList = new DetailItemsList({
        itemType: "labelvalue",
        name: "bygninger_ingen"
      })
      detailItemsList.append({
        type: "labelvalue",
        value: "Ingen bygninger fundet på ejendommen"
      })
      return [detailItemsList.asItem()]
    }
    
  }
}