/**
 * @module
 */
import BbrInfo from "../BbrInfo.js"
export default class BygningsInfoProvider extends BbrInfo {
  constructor(options) {
    super(options)
    if (options.logger)
      options.logger.warn("Septima.Search.Datafordeler.BygningsInfoProvider er deprecated. Brug Septima.Search.Datafordeler.BbrInfo i stedet")
    else
      
      if (console && console.warn) {
        try {
          
          console.warn("Septima.Search.Datafordeler.BygningsInfoProvider er deprecated. Brug Septima.Search.Datafordeler.BbrInfo i stedet")
          // eslint-disable-next-line no-empty
        }catch(e) {}
      }
  }
}